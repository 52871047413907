.alegreya {
  font-family: 'Alegreya Sans SC', sans-serif;
}

.apricots {
  font-family: 'Apricots', cursive;
}

.quicksand {
  font-family: 'Quicksand', sans-serif;
}
