@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@100;300;400;500&family=Cookie&family=Quicksand:wght@300..700&display=swap');
@import url('./typography.css');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./preflight-override.css');
@font-face {
  font-family: 'Apricots';
  src: local('Apricots'), url(../assets/fonts/Apricots.ttf) format('truetype');
}

p,
li {
  font-family: 'Quicksand', sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a:hover {
  color: inherit !important;
}
